@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* fontss -------------------------------------*/
html {
    font-family: 'Nunito Sans', sans-serif;
    src: url("./Nunito_Sans/static/NunitoSans_10pt-Regular.ttf");
 }

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.shadow-feature{
    box-shadow: 2px 1px 25px 5px rgba(166, 166, 166, .2);;
}

.gradient-footer{
    background: linear-gradient(135deg, #8400fc 0%, #11def4 100%)
}

.text-ellipsis {
    max-width: 100%; /* Ensure the element doesn't exceed its container */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
    white-space: nowrap; /* Prevent text from wrapping */
}